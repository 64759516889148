import React, { FC } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  EditProps,
  ReferenceInput,
  SelectInput,
} from "react-admin";

const RequestCreate: FC<EditProps> = (props) => (
  <React.Fragment>
    <Create {...props}>
      <SimpleForm warnWhenUnsavedChanges>
        <ReferenceInput
          label="Budget Item"
          source="item"
          reference="budgetitem"
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <TextInput source="name" />
        <TextInput source="description" />
      </SimpleForm>
    </Create>
  </React.Fragment>
);

export default RequestCreate;
