import * as React from "react";
import { FC, useState } from "react";
import {
  useTranslate,
  TextInput,
  required,
  EditProps,
  FormWithRedirect,
  NumberInput,
  useCreate,
  useNotify,
  useRefresh,
  //   useNotify,
} from "react-admin";
import {
  Button,
  IconButton,
  Box,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import SaveButton from "../../buttons/SaveButton";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
  },
  form: {
    [theme.breakpoints.up("xs")]: {
      width: 500,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30,
    },
  },
  inlineField: {
    display: "inline-block",
    width: "50%",
  },
}));

interface Props extends EditProps {
  onCancel?: () => void;
}

export const Form = (props: any) => {
  //   const submit = (values: any) => {
  //     // React-final-form removes empty values from the form state.
  //     // To allow users to *delete* values, this must be taken into account
  //     props.save(sanitizeEmptyValues(props.record, values));
  //   };
  return (
    <FormWithRedirect
      redirect="?"
      record={props.record}
      basePath={props.basePath}
      save={props.handleSubmit}
      resource={props.resource}
      render={(formProps) => (
        // here starts the custom form layout
        <div>
          <Box p="1em">
            <Box display="flex">
              <Box flex={2} mr="1em">
                <Box display="flex">
                  <Box flex={1} mr="0.5em">
                    <TextInput
                      source="name"
                      resource="budgetitem"
                      fullWidth
                      validate={required()}
                    />
                  </Box>
                  <Box flex={1} ml="0.5em">
                    <NumberInput
                      validate={required()}
                      source="amount"
                      resource="budgetitem"
                      fullWidth
                    />
                  </Box>
                </Box>
                <TextInput
                  validate={required()}
                  resource="budgetitem"
                  source="description"
                  multiline
                  fullWidth
                />
              </Box>
            </Box>
          </Box>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
              <SaveButton
                handleSubmit={formProps.handleSubmitWithRedirect}
                pristine={formProps.pristine}
                saving={formProps.saving}
              />
            </Box>
          </Toolbar>
        </div>
      )}
    />
  );
};

const BudgetItemMiniEdit: FC<Props> = ({ onCancel, ...props }) => {
  const classes = useStyles();
  const [showForm, setShowForm] = useState(false);

  const notify = useNotify();
  const refresh = useRefresh();
  const [create, { loading }] = useCreate("budgetitem", {
    budget: props.id,
  });
  const handleSubmit = async (values: any) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: () => {
          notify("ra.action.save");
          onCancel && onCancel();
          refresh();
        },
        onFailure: ({ error, status, body }) => {
          if (status === 400) {
            if (body.msg.includes("evalMagic condition failed")) {
              notify(
                "You are not allowed to create a budgetitem",
                "warning",
                {},
                false
              );
            } else {
              notify("Invalid form", "warning", {}, false);
            }
          } else {
            notify(error.message, "error");
          }
          refresh();
        },
      }
    );
  };
  const translate = useTranslate();
  if (showForm)
    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h6">
            {translate("resources.budgetitem.add")}
          </Typography>
          <IconButton
            onClick={() => {
              setShowForm(false);
              onCancel && onCancel();
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <Form
          warnWhenUnsavedChanges
          className={classes.form}
          basePath={props.basePath}
          handleSubmit={handleSubmit}
          saving={loading}
          resource="budgetitem"
        />
      </div>
    );
  return (
    <Button
      size="small"
      variant="contained"
      color="secondary"
      onClick={() => setShowForm(true)}
      startIcon={<AddIcon />}
    >
      Add Item
    </Button>
  );
};

export default BudgetItemMiniEdit;
