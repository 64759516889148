import React from "react";
import { FC } from "react";
import {
  EditProps,
  useUpdate,
  useGetOne,
  useNotify,
  useRefresh,
  useTranslate,
} from "react-admin";
import { BudgetItem } from "../../types";
import { Form } from "./BudgetItemMiniEdit";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton, Typography } from "@material-ui/core";

interface Props extends EditProps {
  id: string;
  onCancel?: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 40,
    width: 500,
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
  },
  form: {
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30,
    },
  },
  inlineField: {
    display: "inline-block",
    width: "50%",
  },
}));

const BudgetItemEdit: FC<Props> = ({ onCancel, ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const { data } = useGetOne<BudgetItem>("budgetitem", props.id);
  const [edit, { loading }] = useUpdate("budgetitem", props.id);
  const handleSubmit = async (values: any) => {
    edit(
      { payload: { data: values } },
      {
        onSuccess: () => {
          notify("ra.action.update");
          onCancel && onCancel();
          refresh();
        },
        onFailure: ({ error, status, body }) => {
          if (status === 400) {
            if (body.msg.includes("evalMagic condition failed")) {
              notify(
                "You are not allowed to edit a budget item",
                "warning",
                {},
                false
              );
            } else {
              notify("Invalid form", "warning", {}, false);
            }
          } else {
            notify(error.message, "error");
          }
          refresh();
        },
      }
    );
  };
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">
          {translate("resources.budgetitem.edit")}
        </Typography>
        <IconButton
          onClick={() => {
            onCancel && onCancel();
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Form
        resource="budgetitem"
        record={data}
        saving={loading}
        handleSubmit={handleSubmit}
        pushed={true}
        {...props}
      />
    </div>
  );
};

export default BudgetItemEdit;
