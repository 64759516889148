import React, { FC } from "react";
import {
  TextField,
  NumberField,
  Datagrid,
  useTranslate,
  ReferenceManyField,
  ShowProps,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Budget } from "../../types";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 40,
    [theme.breakpoints.up("xs")]: {
      paddingTop: 80,
      width: 500,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30,
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
  },
  form: {
    [theme.breakpoints.up("xs")]: {
      width: 500,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30,
    },
  },
  content: {
    padding: 20,
  },
  inlineField: {
    display: "inline-block",
    width: "50%",
  },
  listWithDrawer: {
    marginRight: 500,
  },
  drawerPaper: {
    zIndex: 200,
  },
  alert: {
    marginBottom: 20,
  },
}));

interface Props extends ShowProps {
  id: string;
  record?: Budget;
  loading?: boolean;
  onCancel: (budget: any) => void;
}

export const BudgetShow: FC<Props> = ({ record, onCancel, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">
          {translate("resources.budget.page.show")}
        </Typography>
        <IconButton onClick={() => onCancel(props.id)}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.content}>
        {record?.status === "approved" && (
          <Alert severity="warning" className={classes.alert}>
            This budget has been approved. It cannot be modified
          </Alert>
        )}
        <TextField record={record} title="name" source="name" />
        <TextField record={record} title="description" source="description" />
        <ReferenceManyField
          {...props}
          record={record}
          reference="budgetitem"
          target="budget"
          label="resources.budget.fields.items"
          perPage={20}
          fullWidth
        >
          <Datagrid>
            <TextField source="name" />
            <NumberField
              source="amount"
              options={{ minimumFractionDigits: 2 }}
            />
          </Datagrid>
        </ReferenceManyField>
      </div>
    </div>
  );
};
