import React, { FC } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  EditProps,
  ReferenceInput,
  required,
  SelectInput,
} from "react-admin";
import { Route, RouteChildrenProps } from "react-router-dom";

const BudgetCreate: FC<EditProps> = (props) => (
  <Route path="/budget/create/item/add">
    {({ match }: RouteChildrenProps<{ id: string }>) => {
      const isMatch = !!(match && match.params && match.params.id !== "create");
      console.log({ isMatch });
      return (
        <React.Fragment>
          <Create {...props}>
            <SimpleForm warnWhenUnsavedChanges>
              <ReferenceInput
                label="Project"
                source="project"
                reference="project"
                validate={required()}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <TextInput source="name" />
              <TextInput source="description" />
            </SimpleForm>
          </Create>
        </React.Fragment>
      );
    }}
  </Route>
);

export default BudgetCreate;
