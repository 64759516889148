import * as React from "react";
import { Admin, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";

import "./App.css";

import authProvider from "./authProvider";
// import themeReducer from './themeReducer';
import { Login, Layout } from "./layout";
// import { Dashboard } from './dashboard';
// import customRoutes from './routes';
import englishMessages from "./i18n/en";

import dataProvider from "./dataProvider";
import project from "./resources/project";
import budget from "./resources/budget";
import request from "./resources/request";
import { Dashboard } from "./dashboard";
import customRoutes from "./Routes";

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "fr") {
    return import("./i18n/fr").then((messages) => messages.default);
  }

  // Always fallback on english
  return englishMessages;
}, "en");

const App = () => {
  return (
    <Admin
      title=""
      dataProvider={dataProvider}
      dashboard={Dashboard}
      // customReducers={{ theme: themeReducer }}
      customRoutes={customRoutes}
      authProvider={authProvider}
      // dashboard={Dashboard}
      loginPage={Login}
      layout={Layout}
      i18nProvider={i18nProvider}
    >
      {(permissions) => {
        return [
          <Resource name="nones" />,
          permissions && permissions.includes("view project") ? (
            <Resource name="project" {...project} />
          ) : null,
          permissions && permissions.includes("view budget") ? (
            <Resource name="budget" {...budget} />
          ) : null,
          permissions && permissions.includes("view request") ? (
            <Resource name="request" {...request} />
          ) : null,
          permissions && permissions.includes("view budgetitem") ? (
            <Resource name="budgetitem" />
          ) : null,
        ];
      }}
    </Admin>
  );
};

export default App;
