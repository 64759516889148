import * as React from "react";
import { Route } from "react-router-dom";
import { Title } from "react-admin";
import { Drawer, Card, CardContent } from "@material-ui/core";

const Baz = () => (
  <Drawer variant="persistent" open={true} anchor="right">
    <Card>
      <Title title="My Page" />
      <CardContent>...</CardContent>
    </Card>
  </Drawer>
);

const routes = [<Route exact path="/23***" component={Baz} noLayout />];
export default routes;
