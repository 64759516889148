import RequestCreate from "./RequestCreate";
import RequestEdit from "./RequestEdit";
import RequestList from "./RequestList";

const Request = {
  list: RequestList,
  create: RequestCreate,
  edit: RequestEdit,
  // icon: BudgetIcon,
};

export default Request;
