import React, { FC, useCallback } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  EditProps,
  TitleProps,
  Datagrid,
  EditButton,
  NumberField,
  ReferenceManyField,
  TextField,
  useGetOne,
  ShowButton,
} from "react-admin";
import { ButtonProps } from "ra-ui-materialui/lib/button";
import { Route, RouteChildrenProps, useHistory } from "react-router-dom";
import BudgetMiniEdit from "../budget/BudgetMiniEdit";

import { makeStyles } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core";
import BudgetEdit from "../budget/BudgetEdit";
import { Budget, Project } from "../../types";
import { BudgetShow } from "../budget/BudgetShow";

const ProjectTitle: FC<TitleProps> = ({ record }) => {
  return <span>Project {record ? `"${record.name}"` : ""}</span>;
};

interface ShowEditButtonProps extends ButtonProps {
  parent: any;
  permissions: string[];
}

const ShowEditButton: FC<ShowEditButtonProps> = ({
  record,
  parent,
  permissions,
  ...rest
}) => {
  const to = `/project/${parent.id}/budget/${record?.id}`;
  if (record?.status === "approved") {
    // if (!permissions.includes("approve budget")) {
    //   return null;
    // }
    return <ShowButton {...rest} record={record} to={to} />;
  } else if (!permissions.includes("edit budget")) {
    return null;
  }
  return (
    // <ShowButton record={record} {...rest} />
    <EditButton {...rest} record={record} to={to} />
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // width: 400,
  },
  edit: {
    flexGrow: 1,
    transition: theme.transitions.create(["all"], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 500,
  },
  drawerPaper: {
    zIndex: 100,
  },
}));

const BudgetModal: FC<{
  isMatch?: boolean;
  project?: Project | undefined;
  id: string;
  match?: any;
  basePath?: string;
  handleClose: Function;
}> = ({ isMatch, match, handleClose, basePath }) => {
  const { data, loading } = useGetOne<Budget>("budget", match?.params?.id);
  //   if (!match) return null;
  //   if (loading) return null;
  if (data?.status === "approved") {
    return (
      <BudgetShow
        id={`${data.id}`}
        loading={loading}
        basePath={basePath}
        resource="budget"
        record={data}
        onCancel={() =>
          handleClose((match as any).params.project, (match as any).params.id)
        }
      />
    );
  }
  return (
    <BudgetEdit
      data={data}
      loading={loading}
      onCancel={(id) =>
        handleClose((match as any).params.project, (match as any).params.id)
      }
      basePath={basePath}
      id={match?.params?.id}
      resource={"budget"}
    />
  );
};

const ProjectEdit: FC<EditProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { permissions } = props;
  const handleClose = useCallback(
    (project, budget) => {
      history.push(`/project/${project}`);
    },
    [history]
  );
  return (
    <div className={classes.root}>
      <Route path="/project/:project/budget/:id">
        {({ match }: RouteChildrenProps<{ id: string }>) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== "create"
          );
          return (
            <React.Fragment>
              <Edit {...props} title={<ProjectTitle />}>
                <SimpleForm warnWhenUnsavedChanges>
                  <TextInput source="name" />
                  <TextInput source="description" />
                  {permissions && permissions.includes("create budget") && (
                    <BudgetMiniEdit />
                  )}
                  <ReferenceManyField
                    reference="budget"
                    target="project"
                    label="resources.budget.fields.items"
                    perPage={20}
                    fullWidth
                  >
                    <Datagrid>
                      <TextField source="name" />
                      <NumberField
                        source="amount"
                        options={{ minimumFractionDigits: 2 }}
                      />
                      <TextField source="status" />
                      <ShowEditButton
                        parent={props}
                        permissions={permissions}
                      />
                    </Datagrid>
                  </ReferenceManyField>
                </SimpleForm>
              </Edit>
              <Drawer
                variant="persistent"
                open={isMatch}
                anchor="right"
                onClose={() =>
                  handleClose(
                    (match as any).params.project,
                    (match as any).params.id
                  )
                }
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {isMatch && (
                  <BudgetModal
                    match={match}
                    handleClose={handleClose}
                    isMatch={isMatch}
                    id={match && (match as any).params.id}
                    basePath={
                      match ? `/project/${(match as any).params.project}` : ""
                    }
                  />
                )}
              </Drawer>
            </React.Fragment>
          );
        }}
      </Route>
    </div>
  );
};

export default ProjectEdit;
