import * as React from "react";
import { FC } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import ThumbUp from "@material-ui/icons/ThumbUp";
import {
  useTranslate,
  useUpdate,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { Budget } from "../../types";

/**
 * This custom button demonstrate using useUpdate to update data
 */
const AcceptButton: FC<{ record: Budget }> = ({ record }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const redirectTo = useRedirect();
  const refresh = useRefresh();

  const [approve, { loading }] = useUpdate(
    "budget",
    record.id,
    { status: "approved" },
    record,
    {
      undoable: true,
      onSuccess: () => {
        notify(
          "resources.budget.notification.approved_success",
          "info",
          {},
          true
        );
        redirectTo(`/project/${record.project}`);
      },
      onFailure: () => {
        notify("resources.budget.notification.approved_error", "warning");
        refresh();
        redirectTo(`/project/${record.project}/${record.id}`);
      },
    }
  );
  return record && record.status === "pending" ? (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={approve}
      disabled={loading}
    >
      <ThumbUp
        color="primary"
        style={{ paddingRight: "0.5em", color: "green" }}
      />
      {translate("resources.budget.action.accept")}
    </Button>
  ) : (
    <span />
  );
};

AcceptButton.propTypes = {
  record: PropTypes.any,
};

export default AcceptButton;
