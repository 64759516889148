import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import {
  CreateParams,
  DataProvider,
  DeleteManyParams,
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  UpdateManyParams,
  UpdateParams,
} from "ra-core";

const apiUrl = process.env.REACT_APP_API_URL + "/store";
const apiKey = process.env.REACT_APP_API_KEY;
const headers = new Headers();
headers.set("x-dostow-group-access-key", apiKey as string);

const defaultOptions = { headers };

export const httpClient = (url: any, options?: fetchUtils.Options) => {
  const mergedOptions = { ...options, ...defaultOptions };
  const token = localStorage.getItem("token");
  if (token) {
    mergedOptions.headers.set("Authorization", `Bearer ${token}`);
  }
  return fetchUtils.fetchJson(url, mergedOptions);
};

interface DDeleteManyParams extends DeleteManyParams {
  data?: any;
}

const Rest: DataProvider = {
  getList: (resource: string, params: GetListParams) => {
    const { page, perPage } = params.pagination;
    // const { field, order } = params.sort;
    const query: {
      size: number;
      skip: number;
      q?: string;
    } = {
      //   sort: JSON.stringify([field, order]),
      //   range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      size: perPage,
      skip: (page - 1) * perPage,
    };
    // if (params.include != null) {
    //   query.include = include;
    // }
    if (params.filter) {
      query.q = JSON.stringify(params.filter);
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => ({
      data: json.data,
      total: parseInt(json.total_count, 10),
    }));
  },

  getOne: (resource: string, params: GetOneParams) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource: string, params: GetManyParams) => {
    return Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`).then(({ json }) => json)
      )
    ).then((results) => ({
      data: results,
    }));
  },

  getManyReference: (resource: string, params: GetManyReferenceParams) => {
    const { page, perPage } = params.pagination;
    // const { field, order } = params.sort;
    const query = {
      // sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      q: JSON.stringify({
        // ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json.data,
      total: json.total_count,
    }));
  },

  update: (resource: string, params: UpdateParams) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  updateMany: (resource: string, params: UpdateManyParams) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource: string, params: CreateParams) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json, ...rest }) => {
      console.log({ rest });
      return { data: { ...params.data, id: json.id } };
    }),
  // .catch((error) => {
  //   console.log(error);
  //   return { data: { id: "" } } ;
  // }),

  delete: (resource: string, params: DeleteParams) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource: string, params: DDeleteManyParams) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
};

export default Rest;
