import * as React from "react";
import { FC } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery, Theme, Box } from "@material-ui/core";
import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  usePermissions,
} from "react-admin";

import { AppState } from "../types";

// type MenuName = "menuCatalog" | "menuSales" | "menuCustomers";

const Menu: FC<any> = ({ onMenuClick, logout, dense = false }) => {
  //   const [, setState] = useState({
  //     menuCatalog: true,
  //     menuSales: true,
  //     menuCustomers: true,
  //   });
  const { loading, permissions } = usePermissions();
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  return (
    <Box mt={1}>
      {" "}
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      {/* <SubMenu
        handleToggle={() => handleToggle("menuSales")}
        isOpen={state.menuSales}
        sidebarIsOpen={open}
        name="pos.menu.sales"
        // icon={<orders.icon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/commands`}
          primaryText={translate(`resources.commands.name`, {
            smart_count: 2,
          })}
          //   leftIcon={<orders.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/invoices`}
          primaryText={translate(`resources.invoices.name`, {
            smart_count: 2,
          })}
          //   leftIcon={<invoices.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuCatalog")}
        isOpen={state.menuCatalog}
        sidebarIsOpen={open}
        name="pos.menu.catalog"
        // icon={<products.icon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/products`}
          primaryText={translate(`resources.products.name`, {
            smart_count: 2,
          })}
          //   leftIcon={<products.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/categories`}
          primaryText={translate(`resources.categories.name`, {
            smart_count: 2,
          })}
          //   leftIcon={<categories.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu> */}
      {/* <SubMenu
        handleToggle={() => handleToggle("menuCustomers")}
        isOpen={state.menuCustomers}
        sidebarIsOpen={open}
        name="pos.menu.customers"
        icon={<visitors.icon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/customers`}
          primaryText={translate(`resources.customers.name`, {
            smart_count: 2,
          })}
          //   leftIcon={<visitors.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/segments`}
          primaryText={translate(`resources.segments.name`, {
            smart_count: 2,
          })}
          //   leftIcon={<LabelIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu> */}
      {!loading && permissions && permissions.includes("view project") && (
        <MenuItemLink
          to={`/project`}
          primaryText={translate(`resources.project.name`, {
            smart_count: 2,
          })}
          // leftIcon={<reviews.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {/* {!loading && permissions && permissions.budget_manager && (
        <MenuItemLink
          to={`/budget`}
          primaryText={translate(`resources.budget.name`, {
            smart_count: 2,
          })}
          // leftIcon={<reviews.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )} */}
      {/* {!loading && permissions && permissions.budget_manager && (
        <MenuItemLink
          to={`/budgetitem`}
          primaryText={translate(`resources.budgetitem.name`, {
            smart_count: 2,
          })}
          // leftIcon={<reviews.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )} */}
      {isXSmall && (
        <MenuItemLink
          to="/configuration"
          primaryText={translate("pos.configuration")}
          //   leftIcon={<SettingsIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {isXSmall && logout}
    </Box>
  );
};

export default Menu;
