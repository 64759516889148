import * as React from "react";
import { FC, useCallback } from "react";
import {
  useTranslate,
  TextInput,
  EditProps,
  Datagrid,
  EditButton,
  NumberField,
  ReferenceManyField,
  TextField,
  DeleteWithConfirmButton,
  useRefresh,
  useNotify,
  FormWithRedirect,
  useUpdate,
  required,
} from "react-admin";
import { IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Route, RouteChildrenProps, useHistory } from "react-router-dom";
import BudgetItemMiniEdit from "../budgetitem/BudgetItemMiniEdit";
import BudgetEditToolbar from "./BudgetEditToolbar";
import { Drawer } from "@material-ui/core";
import { Budget } from "../../types";
import BudgetItemEdit from "../budgetitem/BudgetItemEdit";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 40,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
  },
  form: {
    [theme.breakpoints.up("xs")]: {
      width: 500,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30,
    },
  },
  content: {
    padding: 20,
  },
  inlineField: {
    display: "inline-block",
    width: "50%",
  },
  listWithDrawer: {
    marginRight: 500,
  },
  drawerPaper: {
    zIndex: 200,
  },
}));

interface Props extends EditProps {
  id: string;
  data?: Budget;
  loading?: boolean;
  onCancel: (budget: any) => void;
}
const HideEditButton: FC<any> = ({ budget, record, parent, ...rest }) => {
  if (!record) return null;
  return budget && budget.status && budget.status === "approved" ? null : ( // <ShowButton record={record} {...rest} />
    <EditButton
      record={record}
      {...rest}
      to={`/project/${budget.project}/budget/${budget.id}/budgetitem/${record.id}`}
    />
  );
};

const HideDeleteButton: FC<any> = ({ budget, record, parent, ...rest }) => {
  if (!record) return null;
  return budget && budget.status && budget.status === "approved" ? null : ( // <ShowButton record={record} {...rest} />
    <DeleteWithConfirmButton
      undoable={false}
      record={record}
      {...rest}
      redirect={`/project/${budget.project}/budget/${budget.id}`}
    />
  );
};

const BudgetForm: FC<any> = ({
  handleSubmitWithRedirect,
  pristine,
  saving,
  data,
  classes,
  parentProps,
}) => {
  return (
    <div className={classes.content}>
      <TextInput name="name" source="name" validate={required()} />
      <TextInput
        name="description"
        source="description"
        rowsMax={15}
        multiline
        fullWidth
      />
      <BudgetItemMiniEdit {...parentProps} />
      <ReferenceManyField
        {...parentProps}
        record={data}
        reference="budgetitem"
        target="budget"
        label="resources.budget.fields.items"
        perPage={20}
        fullWidth
      >
        <Datagrid>
          <TextField source="name" />
          <NumberField source="amount" options={{ minimumFractionDigits: 2 }} />
          <HideEditButton budget={data} />
          <HideDeleteButton budget={data} />
        </Datagrid>
      </ReferenceManyField>
      <BudgetEditToolbar
        record={data as Budget}
        handleSubmitWithRedirect={handleSubmitWithRedirect}
        pristine={pristine}
        saving={saving}
      />
    </div>
  );
};

const BudgetEdit: FC<Props> = ({ onCancel, data, ...props }) => {
  const classes = useStyles();
  const history = useHistory();
  const translate = useTranslate();
  const handleClose = useCallback(
    (project, budget) => {
      history.push(`/project/${project}/budget/${budget}`);
    },
    [history]
  );
  const notify = useNotify();
  const refresh = useRefresh();
  const [edit, { loading }] = useUpdate("budget", props.id);
  const handleSubmit = async (values: any) => {
    edit(
      { payload: { data: values } },
      {
        onSuccess: () => {
          notify("ra.action.save");
          onCancel(props?.id);
          refresh();
        },
        onFailure: ({ error, status, body }) => {
          if (status === 400) {
            if (body.msg.includes("evalMagic condition failed")) {
              notify(
                "You are not allowed to create a budget",
                "warning",
                {},
                false
              );
            } else {
              notify("Invalid form", "warning", {}, false);
            }
          } else {
            notify(error.message, "error");
          }
          refresh();
        },
      }
    );
  };
  return (
    <div className={classes.root}>
      <Route path="/project/:project/budget/:budget/budgetitem/:id">
        {({ match }: RouteChildrenProps<{ id: string }>) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== "create"
          );
          return (
            <React.Fragment>
              <div className={classes.title}>
                <Typography variant="h6">
                  {translate("resources.budget.page.edit")}
                </Typography>
                <IconButton onClick={() => onCancel(props.id)}>
                  <CloseIcon />
                </IconButton>
              </div>
              {props.id === data?.id && (
                <FormWithRedirect
                  warnWhenUnsavedChanges
                  className={classes.form}
                  basePath={props.basePath}
                  record={data}
                  save={handleSubmit}
                  resource="budget"
                  render={(p) => (
                    <BudgetForm
                      {...p}
                      saving={loading}
                      parentProps={props}
                      classes={classes}
                      data={data}
                    />
                  )}
                />
              )}
              <Drawer
                variant="persistent"
                open={isMatch}
                anchor="right"
                onClose={() =>
                  handleClose(
                    (match as any).params.project,
                    (match as any).params.budget
                  )
                }
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {isMatch ? (
                  <BudgetItemEdit
                    onCancel={() =>
                      handleClose(
                        (match as any).params.project,
                        (match as any).params.budget
                      )
                    }
                    {...props}
                    basePath={`/project/${
                      (match as any).params.project
                    }/budget/${(match as any).params.budget}`}
                    id={(match as any).params.id}
                    resource={"budgetitem"}
                  />
                ) : null}
              </Drawer>
            </React.Fragment>
          );
        }}
      </Route>
    </div>
  );
};

export default BudgetEdit;
