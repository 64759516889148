import BudgetIcon from "@material-ui/icons/Collections";
import BudgetList from "./BudgetList";
// import BudgetEdit from "./BudgetEdit";
import BudgetCreate from "./BudgetCreate";

const Budget = {
  list: BudgetList,
  create: BudgetCreate,
  // edit: BudgetEdit,
  icon: BudgetIcon,
};
export default Budget;
