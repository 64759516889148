import * as React from "react";
import { FC, useState } from "react";
import {
  useTranslate,
  TextInput,
  required,
  EditProps,
  FormWithRedirect,
  SaveButton,
  useCreate,
  useNotify,
  useRefresh,
  //   useNotify,
} from "react-admin";
import {
  Card,
  Button,
  IconButton,
  Box,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
  },
  form: {
    [theme.breakpoints.up("xs")]: {
      width: 500,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30,
    },
  },
  inlineField: {
    display: "inline-block",
    width: "50%",
  },
}));

interface Props extends EditProps {
  onCancel?: () => void;
}

const BudgetSaveButton = (props: any) => {
  //   const notify = useNotify();
  //   const redirect = useRedirect();
  //   const onSuccess = (response: any) => {
  //     notify(`Budget Item "${response.data.name}" added!`);
  //     //   redirect('/posts');
  //   };
  return (
    <SaveButton
      {...props}
      //   onSuccess={onSuccess}
    />
  );
};

const Form = (props: any) => (
  <FormWithRedirect
    {...props}
    redirect="?"
    render={(formProps) => (
      // here starts the custom form layout
      <Card>
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={2} mr="1em">
                <Box display="flex">
                  <Box flex={1} mr="0.5em">
                    <TextInput
                      source="name"
                      resource="budget"
                      fullWidth
                      validate={required()}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
              <BudgetSaveButton
                label="Add"
                redirect=""
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
            </Box>
          </Toolbar>
        </form>
      </Card>
    )}
  />
);

const BudgetMiniEdit: FC<Props> = ({ onCancel, ...rest }) => {
  const classes = useStyles();
  const [showForm, setShowForm] = useState(false);
  const { record } = rest as { record: { id: string; approvedBudget?: any } };
  const [create] = useCreate("budget");
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const handleSubmit = async (values: any) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: () => {
          setShowForm(false);
          refresh();
        },
        onFailure: ({ error, status, body }) => {
          if (status === 400) {
            if (body.msg.includes("evalMagic condition failed")) {
              notify(
                "You are not allowed to create a budget",
                "warning",
                {},
                false
              );
            }
          } else {
            notify(error.message, "error");
          }
        },
      }
    );
  };
  if (record.approvedBudget) return null;
  if (showForm)
    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h6">
            {translate("resources.budget.page.create")}
          </Typography>
          <IconButton
            onClick={() => {
              setShowForm(false);
              onCancel && onCancel();
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <Form
          resource="budget"
          record={{ project: record.id }}
          save={handleSubmit}
        />
      </div>
    );
  return (
    <Button
      size="small"
      variant="contained"
      color="secondary"
      onClick={() => setShowForm(true)}
      startIcon={<AddIcon />}
    >
      Add Budget
    </Button>
  );
};

export default BudgetMiniEdit;
