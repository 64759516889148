import ProjectCreate from "./ProjectCreate";
import ProjectEdit from "./ProjectEdit";
import ProjectList from "./ProjectList";

const Project = {
  list: ProjectList,
  create: ProjectCreate,
  edit: ProjectEdit,
  // icon: BudgetIcon,
};

export default Project;
