import { RoleMember } from "./types";

const apiUrl = process.env.REACT_APP_API_URL + "/auth";
const apiKey = process.env.REACT_APP_API_KEY;
const headers = new Headers();
headers.set("x-dostow-group-access-key", apiKey as string);
headers.set("Content-Type", "application/json");

const setRoles = (resp: { roles: RoleMember[] }) => {
  if (resp?.roles) {
    const roles: any = {};
    let permissions: any = [];
    const rolemembers: RoleMember[] = resp.roles;
    rolemembers.forEach(({ role }) => {
      roles[role.name] = role.permissions;
      permissions = permissions.concat(
        role.permissions ? role.permissions.map((v) => v.name) : []
      );
    });

    localStorage.setItem("permissions", JSON.stringify(permissions));
    localStorage.setItem("roles", JSON.stringify(roles));
  }
};
const authProvider = {
  // called when the user attempts to log in
  login: ({ username, password }: { username: string; password: string }) => {
    const request = new Request(`${apiUrl}/sign_in`, {
      headers,
      method: "POST",
      body: JSON.stringify({ Username: username, Password: password }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token, data }) => {
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(data));
        setRoles(data);
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    localStorage.removeItem("permissions");
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const data = localStorage.getItem("user");
      if (data === "[object Object]") {
        localStorage.removeItem("token");
        localStorage.removeItem("roles");
        localStorage.removeItem("permissions");
        return Promise.reject(new Error("invalid credentials"));
      }
      const { id, Username, avatar, role } = JSON.parse(
        localStorage.getItem("user") as string
      );

      const roles = JSON.parse(localStorage.getItem("roles") as string);
      // console.log(rest);
      return Promise.resolve({
        id,
        fullName: Username + " | " + role,
        Username,
        avatar: avatar?.url || "",
        role,
        roles,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // called when the API returns an error
  checkError: ({ status }: { status: number }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("roles");
      localStorage.removeItem("permissions");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    // fetch user from server
    const token = localStorage.getItem("token");
    if (!token) return Promise.reject();
    return Promise.resolve();
    // headers.set("Authorization", "Bearer " + token);
    // const request = new Request(`${apiUrl}/me`, {
    //   headers,
    //   method: "GET",
    // });
    // return fetch(request)
    //   .then((response) => {
    //     if (response.status < 200 || response.status >= 300) {
    //       throw new Error(response.statusText);
    //     }
    //     return response.json();
    //   })
    //   .then((resp) => {
    //     // localStorage.setItem("token", token);
    //     localStorage.setItem("user", resp);
    //     setRoles(resp);
    //     return Promise.resolve();
    //   });
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const permissions = JSON.parse(
      localStorage.getItem("permissions") as string
    );
    return Promise.resolve(permissions);
  },
};

export default authProvider;
