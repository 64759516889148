import * as React from "react";
import { FC } from "react";
import { Box, Card, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useGetIdentity, usePermissions } from "react-admin";

import publishArticleImage from "../logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      theme.palette.type === "dark"
        ? "#535353"
        : `linear-gradient(to right, #8975fb 0%, #746be7 35%), linear-gradient(to bottom, #8975fb 0%, #6f4ceb 50%), #6f4ceb`,

    color: "#fff",
    padding: 20,
    marginTop: theme.spacing(2),
    marginBottom: "1em",
  },
  media: {
    background: `url(${publishArticleImage}) top right  / cover`,
    marginLeft: "auto",
  },
  actions: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
      flexWrap: "wrap",
      "& a": {
        marginTop: "1em",
        marginLeft: "0!important",
        marginRight: "1em",
      },
    },
  },
}));

function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

const Welcome: FC = () => {
  const classes = useStyles();
  const { identity } = useGetIdentity();
  const perms = usePermissions();
  return (
    <Card className={classes.root}>
      <Box display="flex">
        <Box flex="1">
          <Typography variant="h5" component="h2" gutterBottom>
            Welcome {identity?.Username}
          </Typography>
          <Box maxWidth="40em">
            <Typography variant="body1" component="p" gutterBottom>
              You are logged in as a {identity?.role}. The following permissions
              are assigned to you
              {!perms.loading &&
                perms.loaded &&
                Object.keys(identity?.roles).map((perm) => {
                  return (
                    <p key={perm}>
                      {perm.split("_").map(capitalizeFirstLetter).join(" ")}
                    </p>
                  );
                })}
            </Typography>
          </Box>
        </Box>
        <Box
          display={{ xs: "none", sm: "none", md: "block" }}
          className={classes.media}
          width="16em"
          height="10em"
          overflow="hidden"
        />
      </Box>
    </Card>
  );
};

export default Welcome;
