import * as React from "react";
import { FC } from "react";
import {
  Identifier,
  Datagrid,
  DateField,
  TextField,
  DatagridProps,
  FunctionField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
// import { Project } from "../../types";

// import ProductReferenceField from "../products/ProductReferenceField";
// import CustomerReferenceField from "../visitors/CustomerReferenceField";
// import StarRatingField from "./StarRatingField";
// import rowStyle from "./rowStyle";

const useListStyles = makeStyles({
  headerRow: {
    borderLeftColor: "white",
    borderLeftWidth: 5,
    borderLeftStyle: "solid",
  },
  headerCell: {
    padding: "6px 8px 6px 8px",
  },
  rowCell: {
    padding: "6px 8px 6px 8px",
  },
  comment: {
    maxWidth: "18em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

export interface RequestListDesktopProps extends DatagridProps {
  selectedRow?: Identifier;
}

const RequestListDesktop: FC<RequestListDesktopProps> = ({
  selectedRow,
  ...props
}) => {
  const classes = useListStyles();
  return (
    <Datagrid
      rowClick="edit"
      // @ts-ignore
      //   rowStyle={rowStyle(selectedRow)}
      classes={{
        headerRow: classes.headerRow,
        headerCell: classes.headerCell,
        rowCell: classes.rowCell,
      }}
      optimized
      {...props}
    >
      <DateField source="created_at" />
      {/* <CustomerReferenceField link={false} />
      <ProductReferenceField link={false} />
      <StarRatingField size="small" /> */}
      <TextField source="name" cellClassName={classes.comment} />
      <TextField source="description" />
      <FunctionField
        label="Status"
        render={(record: any) =>
          `${record.approvedBudget ? "Budget Approved" : "pending"}`
        }
      />
    </Datagrid>
  );
};

export default RequestListDesktop;
