import React, { FC } from "react";
import { Create, SimpleForm, TextInput, EditProps } from "react-admin";

const RequestEdit: FC<EditProps> = (props) => (
  <React.Fragment>
    <Create {...props}>
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput source="name" />
        <TextInput source="description" />
      </SimpleForm>
    </Create>
  </React.Fragment>
);

export default RequestEdit;
