import React, {
  useState,
  useEffect,
  useCallback,
  FC,
  CSSProperties,
} from "react";
import { useVersion, useDataProvider } from "react-admin";
import { useMediaQuery, Theme } from "@material-ui/core";

import Welcome from "./Welcome";

import { Customer, Order, Project } from "../types";
import PendingProjects from "./PendingReviews";

// interface OrderStats {
//   revenue: number;
//   nbNewOrders: number;
//   pendingOrders: Order[];
// }

interface CustomerData {
  [key: string]: Customer;
}

interface State {
  nbNewOrders?: number;
  nbPendingProjects?: number;
  pendingOrders?: Order[];
  pendingOrdersCustomers?: CustomerData;
  pendingProjects?: Project[];
  pendingProjectsCustomers?: CustomerData;
  recentOrders?: Order[];
  revenue?: string;
}

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const Spacer = () => <span style={{ width: "1em" }} />;
// const VerticalSpacer = () => <span style={{ height: "1em" }} />;

const Dashboard: FC = () => {
  const [state, setState] = useState<State>({});
  const version = useVersion();
  const dataProvider = useDataProvider();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const fetchProjects = useCallback(async () => {
    const { data: Projects } = await dataProvider.getList<Project>("project", {
      filter: {},
      sort: { field: "date", order: "DESC" },
      pagination: { page: 1, perPage: 100 },
    });
    const nbPendingProjects = Projects.reduce((nb: number) => ++nb, 0);
    const pendingProjects = Projects.slice(0, Math.min(10, Projects.length));
    console.log({ pendingProjects, nbPendingProjects });
    setState((state) => ({ ...state, pendingProjects, nbPendingProjects }));
    // const { data: customers } = await dataProvider.getMany<Customer>(
    //   "customers",
    //   {
    //     ids: pendingProjects.map((Project) => Project.customer_id),
    //   }
    // );
    // setState((state) => ({
    //   ...state,
    //   pendingProjectsCustomers: customers.reduce(
    //     (prev: CustomerData, customer) => {
    //       prev[customer.id] = customer; // eslint-disable-line no-param-reassign
    //       return prev;
    //     },
    //     {}
    //   ),
    // }));
  }, [dataProvider]);

  useEffect(() => {
    // fetchOrders();
    fetchProjects();
  }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

  const { nbPendingProjects, pendingProjects } = state;
  return isXSmall ? (
    <div>
      <div style={styles.flexColumn as CSSProperties}>
        <Welcome />
        {/* <MonthlyRevenue value={revenue} />
        <VerticalSpacer />
        <NbNewOrders value={nbNewOrders} />
        <VerticalSpacer />
        <PendingOrders
          orders={pendingOrders}
          customers={pendingOrdersCustomers}
        /> */}
      </div>
    </div>
  ) : isSmall ? (
    <div style={styles.flexColumn as CSSProperties}>
      <div style={styles.singleCol}>
        <Welcome />
      </div>
      <div style={styles.rightCol}>
        <div style={styles.flex}>
          <PendingProjects nb={nbPendingProjects} projects={pendingProjects} />
          <Spacer />
          {/* <NewCustomers /> */}
        </div>
      </div>
      {/* <div style={styles.flex}>
        <MonthlyRevenue value={revenue} />
        <Spacer />
        <NbNewOrders value={nbNewOrders} />
      </div>
      <div style={styles.singleCol}>
        <OrderChart orders={recentOrders} />
      </div>
      <div style={styles.singleCol}>
        <PendingOrders
          orders={pendingOrders}
          customers={pendingOrdersCustomers}
        />
      </div> */}
    </div>
  ) : (
    <>
      <Welcome />
      <div style={styles.rightCol}>
        <div style={styles.flex}>
          <PendingProjects nb={nbPendingProjects} projects={pendingProjects} />
          <Spacer />
          {/* <NewCustomers /> */}
        </div>
      </div>
      {/* <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <MonthlyRevenue value={revenue} />
                        <Spacer />
                        <NbNewOrders value={nbNewOrders} />
                    </div>
                    <div style={styles.singleCol}>
                        <OrderChart orders={recentOrders} />
                    </div>
                    <div style={styles.singleCol}>
                        <PendingOrders
                            orders={pendingOrders}
                            customers={pendingOrdersCustomers}
                        />
                    </div>
                </div>
                <div style={styles.rightCol}>
                    <div style={styles.flex}>
                        <PendingProjects
                            nb={nbPendingProjects}
                            Projects={pendingProjects}
                            customers={pendingProjectsCustomers}
                        />
                        <Spacer />
                        <NewCustomers />
                    </div>
                </div>
            </div> */}
    </>
  );
};

export default Dashboard;
