import * as React from "react";
import { FC } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Save from "@material-ui/icons/Save";
import { useTranslate } from "react-admin";
import { Budget } from "../types";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { withStyles } from "@material-ui/core/styles";

// const styles = (theme) => ({
//   button: {
//     margin: theme.spacing.unit,
//     paddingLeft: theme.spacing.unit,
//   },

//   input: {
//     display: "none",
//   },

//   circularProgress: {
//     marginLeft: 0,
//     marginRight: theme.spacing.unit,
//   },
// });

const SaveButton: FC<{
  record?: Budget | undefined;
  handleSubmit: any;
  saving: boolean | undefined;
  pristine: boolean | undefined;
}> = ({ handleSubmit, saving, pristine }) => {
  const translate = useTranslate();
  return (
    <Button
      color="primary"
      size="small"
      type="submit"
      onClick={handleSubmit}
      disabled={saving || pristine}
    >
      {saving && <CircularProgress size={20} />}
      {!saving && <Save color="primary" style={{ paddingRight: "0.5em" }} />}
      {translate("ra.action.save")}
    </Button>
  );
};

SaveButton.propTypes = {
  record: PropTypes.any,
};

export default SaveButton;
