import * as React from "react";
import { FC } from "react";
import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CommentIcon from "@material-ui/icons/Comment";
import { Link } from "react-router-dom";
import { useTranslate } from "react-admin";

import CardWithIcon from "./CardWithIcon";
// import StarRatingField from '../reviews/StarRatingField';
import { Customer, Project } from "../types";

interface Props {
  projects?: Project[];
  customers?: { [key: string]: Customer };
  nb?: number;
}

const PendingProjects: FC<Props> = ({ projects = [], customers = {}, nb }) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <CardWithIcon
      to="/project"
      icon={CommentIcon}
      title={translate("pos.dashboard.pending_projects")}
      subtitle={nb}
    >
      <List>
        {projects.map((record: Project) => (
          <ListItem
            key={record.id}
            button
            component={Link}
            to={`/project/${record.id}`}
            alignItems="flex-start"
          >
            <ListItemAvatar>
              {customers[record.customer_id] ? (
                <Avatar
                  src={`${customers[record.customer_id].avatar}?size=32x32`}
                  className={classes.avatar}
                />
              ) : (
                <Avatar />
              )}
            </ListItemAvatar>

            <ListItemText
              // primary={<StarRatingField record={record} />}
              secondary={record.name}
              className={classes.listItemText}
              style={{ paddingRight: 0 }}
            />
          </ListItem>
        ))}
      </List>
      <Box flexGrow="1">&nbsp;</Box>
      <Button
        className={classes.link}
        component={Link}
        to="/project"
        size="small"
        color="primary"
      >
        <Box p={1} className={classes.linkContent}>
          {translate("pos.dashboard.all_projects")}
        </Box>
      </Button>
    </CardWithIcon>
  );
};

const useStyles = makeStyles((theme) => ({
  avatar: {
    background: theme.palette.background.paper,
  },
  listItemText: {
    overflowY: "hidden",
    height: "4em",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
  link: {
    borderRadius: 0,
  },
  linkContent: {
    color: theme.palette.primary.main,
  },
}));

export default PendingProjects;
