import * as React from "react";
import { Fragment, FC } from "react";
import MuiToolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";

import {
  DeleteButton,
  ToolbarProps,
  DeleteWithConfirmButton,
} from "react-admin";
import AcceptButton from "./AcceptButton";
import RejectButton from "./RejectButton";
import { Budget } from "../../types";
import SaveButton from "../../buttons/SaveButton";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "space-between",
  },
}));

const BudgetEditToolbar: FC<ToolbarProps<Budget>> = ({
  basePath,
  handleSubmitWithRedirect,
  record,
  resource,
  saving,
  pristine,
}) => {
  const classes = useStyles();
  return (
    <MuiToolbar className={classes.root}>
      {record?.status === "pending" ? (
        <Fragment>
          <SaveButton
            record={record}
            handleSubmit={handleSubmitWithRedirect}
            saving={saving}
            pristine={pristine}
          />
          <AcceptButton record={record} />
          <RejectButton record={record} />
          <DeleteWithConfirmButton
            confirmTitle={`Delete budget - ${record.name}`}
            confirmContent={`Are you sure you want to delete this budget`}
            basePath={basePath}
            record={record}
            resource={resource}
          />
        </Fragment>
      ) : (
        <Fragment>
          <SaveButton
            record={record}
            handleSubmit={handleSubmitWithRedirect}
            saving={saving}
            pristine={pristine}
          />
          <DeleteButton
            basePath={basePath}
            record={record}
            resource={resource}
          />
        </Fragment>
      )}
    </MuiToolbar>
  );
};

export default BudgetEditToolbar;
