import * as React from "react";
import { Fragment, useCallback, FC } from "react";
import { List, ListProps } from "react-admin";
import { Route, RouteChildrenProps, useHistory } from "react-router-dom";
import { Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RequestListDesktop from "./RequestListDesktop";
import RequestEdit from "./RequestEdit";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(["all"], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 500,
  },
  drawerPaper: {
    zIndex: 100,
  },
}));

const RequestList: FC<ListProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push("/budget");
  }, [history]);

  return (
    <div className={classes.root}>
      <Route path="/budget/:id">
        {({ match }: RouteChildrenProps<{ id: string }>) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== "create"
          );

          return (
            <Fragment>
              <List
                {...props}
                // className={classnames(classes.list, {
                //   [classes.listWithDrawer]: isMatch,
                // })}
                // bulkActionButtons={<BudgetsBulkActionButtons />}
                // filters={<BudgetFilter />}
                // perPage={25}
                // sort={{ field: "date", order: "DESC" }}
              >
                <RequestListDesktop />
              </List>
              <Drawer
                variant="persistent"
                open={isMatch}
                anchor="right"
                onClose={handleClose}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {isMatch ? (
                  <RequestEdit
                    id={(match as any).params.id}
                    // onCancel={handleClose}
                    {...props}
                  />
                ) : null}
              </Drawer>
            </Fragment>
          );
        }}
      </Route>
    </div>
  );
};

export default RequestList;
